import GA from 'springload-analytics.js';

import onPageReady from './utils/onPageReady';
import polyfill from './polyfills/polyfill-loader';

function initialise() {
    GA.init();

    if (__SENTRY_DSN) {
        import(/* webpackChunkName: "sentry" */ './modules/sentry.js')
            .then(({ default: sentry }) => {
                sentry.init(__SENTRY_DSN);
            });
    }

    /*  Mobile Menu  ------------------------ */
    const mobileMenu = document.getElementsByClassName('mobile-menu');
    if (mobileMenu) {
        import(/* webpackChunkName: "mobile.menu" */ './modules/mobile-menu.js')
            .then(({ default: mobileInit }) => mobileInit(mobileMenu));
    }

    /*  Background Map  ------------------------ */
    const map = document.getElementById('background-map');
    const mapData = document.getElementById('map-data');
    const mapOptions = document.getElementById('map-options');
    if (map && mapData) {
        import(/* webpackChunkName: "map" */ './modules/map.js')
            .then(({ default: init }) => {
                init(
                    map,
                    JSON.parse(mapData.textContent),
                    (mapOptions) ? JSON.parse(mapOptions.textContent) : {}
                );
            });
    }

    /* Entity select form ----------------- */
    const entitySelectFormEl = document.querySelector('#data-compare-form');
    if (entitySelectFormEl) {
        import(/* webpackChunkName: "entity-select" */ './modules/entity-select')
            .then(({ default: EntitySelectForm }) => {
                new EntitySelectForm(entitySelectFormEl);
            });
    }

    /*  Tables  ------------------------ */
    const tables = document.querySelectorAll('table');
    if (tables.length > 0) {
        import(/* webpackChunkName: "sortable-tables" */ './modules/sortable-tables.js')
            .then(({ default: tablesInit }) => {
                tablesInit([...tables]);
            });
    }

    /*  Collapsibles  ------------------------ */
    const collapsibleEls = document.querySelectorAll('[data-collapse-control], .collapse-control');
    if (collapsibleEls.length > 0) {
        import(/* webpackChunkName: "collapsible" */ './modules/collapsible.js')
            .then(({ default: collapsibleInit }) => {
                collapsibleInit([...collapsibleEls]);
            });
    }
}

polyfill().then(() => onPageReady(initialise));
