/*
* This file should only be used for polyfills not automatically polyfilled by babel
* Mostly this is Dom spec rather than ecmascript spec
*
* This file tests if a feature is needed and if there are any features not supported
* by a browser then polyfills will be loaded.
*
* Browsers that support all features will resolve immediately after targetFeaturesSupported without
* loading the extra Javascript.
* */

const fnAvailable = (fn) => typeof fn === 'function';
const exists = val => val !== undefined;

const targetFeaturesSupported = () => {
    return [
        fnAvailable(Object.assign),
        fnAvailable(Array.from),
        // fnAvailable(window.fetch),
        // fnAvailable(Element.prototype.closest),
        // fnAvailable(Array.prototype.find),
        // fnAvailable(window.CustomEvent),
        // exists(window.location.origin),
    ].every(Boolean);
};

export default function polyfill() {
    return new Promise((resolve) => {
        if (targetFeaturesSupported()) {
            resolve();
        } else {
            import(/* webpackChunkName: "non-core-polyfills" */ './polyfills.js')
                .then(() => resolve());
        }
    });
}
